<template>
  <router-view />
  <section class="footer">
    <div class="logo">
      <img src="@/assets/logo-boards-transparent.png" alt="Boards N Thangs Logo" />
    </div>
    <span class="footer-span">Eunice Payne, Owner.</span>
    <span class="footer-span">Fredericksburg, Virginia</span>
    <div class="footer-options">
      <div class="footer-option">
        <a href="tel:5402876083">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-telephone-fill"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg>
        </a>
        <a href="sms:5402876083">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-chat-dots-fill"
            viewBox="0 0 16 16">
            <path
              d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
          </svg>
        </a>
        <span>(540) 287-6083</span>
      </div>

      <a href="mailto:boardsnthangs@gmail.com" class="footer-option">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-envelope-fill"
          viewBox="0 0 16 16">
          <path
            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
        </svg><span>boardsnthangs@gmail.com</span>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100095063793796" target="_blank" rel="noopener noreferrer" class="footer-option">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-facebook"
          viewBox="0 0 16 16">
          <path
            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </svg>
        <span>Connect With Us</span>
      </a>
    </div>

    <span class="footer-span">Copyright {{ currentYear }} Boards N Thangs, LLC. All rights
      reserved.</span>
    <span class="footer-span">Powered by The NixTech Company</span>
  </section>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const currentYear = ref(new Date().getFullYear());

    return { currentYear };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #855f35;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.footer {
  min-height: 20rem;
  background: linear-gradient(white, #faead7, #ad9577);
  margin-top: 1rem;
  font-family: "Epilogue", sans-serif;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  color: #311d1b;
  padding: 20px;
  font-size: 14px;

}

.footer .logo {
  max-width: 12rem;
  margin-bottom: 10px;
}

.footer .logo img {
  max-width: 100%;
}

.footer-options {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  margin: 5px 0;
}

.footer-option {
  margin: 3px 0;
  display: flex;
  align-items: center;
}

.footer-option,
.footer-span {
  width: 100%;
  text-align: left;
}

.footer svg {
  fill: #311d1b;
  margin-right: 8px;
}

.footer a {
  text-decoration: none;
  color: #311d1b;
  display: flex;
  align-items: center;

}

.footer a span {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
