<template>
  <div class="home">
    <section class="main">
      <div class="logo-container">
        <img src="@/assets/logo-boards-transparent.png" alt="Boards N Thangs Logo" />
      </div>
    </section>
    <div class="main-bg">
      <div class="main-overlay"></div>
      <div class="main-bg-pic">
        <img src="@/assets/main-bg-boards.png" alt="Boards N Thangs background" />
      </div>
      <div class="main-bg-pic-second">
        <img src="@/assets/main-bg-boards-2.png" alt="Boards N Thangs background" />
      </div>
    </div>

    <section class="services">
      <div class="services-image">
        <img src="@/assets/boardsnthangs.jpg" alt="grazing" />
      </div>
      <section class="experiences">
        <span class="experiences-header">EXPERIENCES</span>
        <div class="experiences-options">
          <router-link to="/services?s=catering" class="option catering-option">
            <div class="option-overlay"></div>
            <div class="image-holder">
              <img class="option-img" src="@/assets/catering-boards.jpg" alt="exclusive catering image" />
            </div>
            <span class="experiences-option-text">Exclusive<br />Catering</span>
          </router-link>
          <router-link to="/services?s=charcuterie" class="option charcuterie-option">
            <div class="option-overlay"></div>

            <div class="image-holder">
              <img class="option-img" src="@/assets/charcuterie-boards.jpg" alt="charcuterie board image" />
            </div>
            <span class="experiences-option-text">Charcuterie<br />Boards</span>
          </router-link>
          <router-link to="/services?s=grazing" class="option grazing-option">
            <div class="option-overlay"></div>

            <div class="image-holder">
              <img class="option-img" src="@/assets/grazing-boards.jpg" alt="grazing table image" />
            </div>
            <span class="experiences-option-text">Grazing<br />Tables</span>
          </router-link>
        </div>
      </section>
      <section class="private-chef">
        <span class="private-chef-header">PRIVATE CHEF</span>
        <div class="chef-image-holder">
          <div class="image">
            <img src="@/assets/boardsnthangs-chef.png" alt="Eunice Payne, Chef" />
          </div>
        </div>
        <div class="chef-info">
          <span class="chef-name-text">Chef Payne</span>
          <span class="chef-description-text">Indulging in culinary finesse is not merely a profession for Chef
            Payne, but a graceful symphony of flavors orchestrated with love and
            finesse. With a passion ignited by the allure of exquisite
            ingredients and a mastery honed through years of dedication, Chef
            Payne crafts dining experiences that dance on palates and linger in
            memories.
          </span>
        </div>
      </section>
    </section>
    <div class="divider"></div>
    <section class="testimonials">
      <div class="testimonials-bg">
        <img src="@/assets/testimonials-bg-boards.jpg" alt="Boards M Thangs" />
      </div>
      <span class="testimonials-header">TESTIMONIALS</span>
      <div id="testimonials-carousel" class="carousel slide testimonials-carousel" data-bs-ride="carousel"
        data-bs-interval="8000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="d-block w-100">
              <div class="testimonial">
                <span class="testimonial-text">
                  If you are wanting food made with pure love then you have come
                  to right place. Everything is amazing right down to the
                  presentation. Eunice puts her all into everything she
                  touches!!!! Give it a try, you will not be disappointed.
                </span>
                <span class="testimonial-author"> Syreeta </span>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="d-block w-100">
              <div class="testimonial">
                <span class="testimonial-text">
                  Boards n Thangs brought my vision of meats, fruits and veggies
                  to another level!! The grazing table was beautifully decorated
                  and the guest loved the variety of food choices that were
                  layered all over the table!! She is definitely going to be my
                  go to for all future events!!
                </span>
                <span class="testimonial-author"> Carlisha </span>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="d-block w-100">
              <div class="testimonial">
                <span class="testimonial-text">
                  Everything from homemade garlic butter to crab cakes…No matter
                  what I have had from this amazing company it has been
                  delicious! The owner is super nice, professional and ALWAYS ON
                  TIME!!!
                </span>
                <span class="testimonial-author"> Kiev </span>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="d-block w-100">
              <div class="testimonial">
                <span class="testimonial-text">
                  If you are looking for professionalism, amazing tasting food,
                  top notch service…LOOK NO FURTHER!! Boards n Thangs, LLC
                  offers all of this and more. The chef is absolutely amazing,
                  she pays attention to detail and definitely satisfies your
                  taste buds!!
                </span>
                <span class="testimonial-author"> Erica </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="divider"></div>
    <section class="reserve">
      <div class="reserve-bg">
        <img src="@/assets/reserve-boards.jpg" alt="Boards N Thangs background" />
      </div>
      <span class="reserve-header">RESERVE</span>
      <span class="reserve-subheading">
        Experience the epitome of culinary excellence. Secure your reservation
        today to receive a tailored quote for your upcoming event. Your palate
        deserves nothing less.
      </span>
      <div class="reserve-options">
        <a href="tel:5402876083" class="reserve-option">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
          </svg>
          <span class="reserve-text">(540) 287-6083</span>
        </a>
        <a href="https://www.messenger.com/t/101958002982810" target="_blank" class="reserve-option">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-messenger" viewBox="0 0 16 16">
            <path
              d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
          </svg>
          <span class="reserve-text">Facebook Messenger</span>
        </a>
        <a href="mailto:boardsnthangs@gmail.com" class="reserve-option">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
          </svg>
          <span class="reserve-text">boardsnthangs@gmail.com </span>
        </a>
      </div>
    </section>
    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
<style scoped>
*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #855f35;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main {
  height: 15rem;
  position: relative;
  width: 100%;
}

.divider {
  display: none;
  height: 1px;
  background: #555;
  width: 30rem;
  border-radius: 8%;
  margin: 8px auto;

}

.logo-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container img {
  min-width: 40%;
  max-width: 90%;
  max-height: 80%;
  position: relative;
  z-index: 3;
}

.main-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 18rem;
  width: 100%;
  overflow: hidden;
}

.main-overlay {
  background: #855f35;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
}

.main-bg-pic,
.main-bg-pic-second {
  position: absolute;
  bottom: 0rem;
  right: -1rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60%;
  z-index: 1;
}

.main-bg-pic-second {
  display: none;
  right: unset;
  left: -2rem;
  justify-content: flex-start;
  width: 30%;
  bottom: -2rem;
}

.main-bg-pic img,
.main-bg-pic-second img {
  max-width: 100%;
  max-height: 100%;
}

/* SERVICES */

.services {
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  padding: 20px 15px;
  background: white;
  position: relative;
  z-index: 4;
}

.services-image {
  display: none;
}

.experiences-header,
.private-chef-header,
.testimonials-header,
.reserve-header {
  font-family: "Epilogue", sans-serif;
  color: #311d1b;
  font-size: 18px;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.experiences {
  overflow: hidden;
  height: 22rem;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
}

.experiences-options {
  flex: 1;
  display: flex;
  width: 100%;
  overflow-y: auto;
}

.experiences-options::-webkit-scrollbar,
.experiences-options::-webkit-scrollbar-thumb,
.experiences-options::-webkit-scrollbar-thumb:hover,
.experiences-options::-webkit-scrollbar-track {
  width: 0px;
  height: 0px;
  background: transparent;
  box-shadow: none;
}

.option {
  display: flex;
  text-decoration: none;
  font-family: "Shrikhand", cursive;
  width: 14rem;
  margin: 10px;
  border-radius: 5%;
  flex-shrink: 0;
  flex-flow: column wrap;
  background: #f1eded;
  position: relative;
}

.option-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  z-index: 2;
  opacity: 0.7;
}

.option.catering-option .option-overlay {
  background: #dab585;
}

.option.charcuterie-option .option-overlay {
  background: #d4a469;
}

.option.grazing-option .option-overlay {
  background: #97754c;
}

.experiences-option-text {
  font-size: 24px;
  width: 100%;
  margin-bottom: 1rem;
  color: #fff6e6;
  position: relative;
  z-index: 3;
}

.image-holder {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
}

.image-holder img {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  border: 3px solid white;
  z-index: 2;
  opacity: 0.8;
}

/* PRIVATE CHEF */

.private-chef {
  display: flex;
  flex-flow: row wrap;
  padding: 15px;
}

.chef-image-holder {
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  padding-top: 0px;
}

.chef-image-holder .image {
  background: #855f35;
  width: 100%;
  height: 100%;
  max-width: 12rem;
  max-height: 12rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}

.chef-image-holder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.chef-info {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  width: 100%;
}

.chef-name-text {
  font-family: "Lobster", cursive;
  font-size: 21px;
  color: #855f35;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.chef-description-text {
  font-family: "Lobster", cursive;
  color: #311d1b;
  font-size: 16px;
  width: 90%;
  max-width: 24rem;
  margin: 0 auto;
}

/* Testimonials */

.testimonials {
  height: 14rem;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding: 0px 30px;
  align-content: flex-start;
  position: relative;
  padding-top: 15px;
}

.testimonials-carousel {
  max-width: 40rem;
  margin: 0 auto;
  flex: 1;
  display: flex;
  align-items: center;
}

.testimonial {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  font-family: "Lobster", cursive;
  color: #311d1b;
  font-size: 14px;
  max-width: 40rem;
}

.testimonial-author {
  width: 100%;
  text-align: right;
  margin-top: 15px;
  margin-right: 4rem;
}

.testimonials-bg {
  display: none;
}

/* RESERVE */
.reserve {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 2rem;
  position: relative;
  align-content: flex-start;
}

.reserve-subheading {
  font-family: "Epilogue", sans-serif;
  font-size: 14px;
  color: #311d1b;
  text-align: left;
  margin-top: 20px;
  max-width: 40rem;
}

.reserve-options {
  padding-left: .75rem;
  margin-top: 1.4rem;
  max-width: 20rem;
}

.reserve-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  text-decoration: none;
}

.reserve-option svg {
  width: 28px;
  height: 28px;
  fill: #97754c;
}

.reserve-text {
  font-size: 14px;
  color: #311d1b;
  font-family: "Epilogue", sans-serif;
  margin-left: 0.7rem;
}

.reserve-bg {
  display: none;
}

@media only screen and (min-width: 950px) {
  .main {
    height: 30rem;
  }

  .main-bg {
    height: 33rem;
  }

  .main-bg-pic-second {
    display: flex;
    bottom: -5rem;
  }

  .chef-image-holder {
    justify-content: flex-end;
    padding: 20px;
    height: unset;
    width: 25%;
  }

  .chef-image-holder .image {
    max-width: unset;
    max-height: unset;
    width: 16rem;
    height: 16rem;
  }

  .chef-info {
    justify-content: flex-start;
    padding-left: 1rem;
    flex: 1;
  }

  .chef-name-text {
    margin-bottom: 10px;
  }

  .chef-name-text,
  .chef-description-text {
    text-align: left;
    width: 100%;
  }

  .chef-description-text {
    margin: unset;
  }

  .testimonial {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1250px) {
  .divider {
    display: block;
  }

  .testimonials {
    height: 18rem;
  }

  .services-image,
  .testimonials-bg,
  .reserve-bg {
    position: absolute;
    display: flex;
    height: 25rem;
    width: 20rem;
    border-radius: 3%;
    box-shadow: 0 0 20px #855f35;
    z-index: 3;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 8rem;
  }

  .testimonials-bg {
    height: 14rem;
    width: 12rem;
    right: 10rem;
    margin-bottom: 2rem;
  }

  .reserve {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .reserve-bg {
    width: 20rem;
    height: 12rem;
  }

  .services-image img,
  .testimonials-bg img,
  .reserve-bg img {
    object-fit: cover;
    width: 100%;
    border-radius: 3%;
  }

  .testimonials-carousel {
    margin: unset;
    margin-left: 5rem;
  }

  .reserve-subheading {
    font-size: 16px;
  }

  .reserve-option svg {
    width: 26px;
    height: 26px;
  }

  .reserve-text {
    font-size: 14px;
  }
}
</style>
