<template>
  <div class="not-found">
    <router-link to="/" class="logo-container">
        <img src="@/assets/logo-boards-transparent.png" alt="Boards N Thangs Logo" />
    </router-link>
    <div class="not-found-header">
      <span class="not-found-header-text">Oops!</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-emoji-frown-fill"
        viewBox="0 0 16 16">
        <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm-2.715 5.933a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8z" />
      </svg>
    </div>
    <span class="not-found-description">Sorry, the page you are looking for does not exist.</span>
    <router-link class="return-to-home" to="/">Return to Home</router-link>
  </div>
</template>

<style scoped>
.not-found {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
  font-family: "Epilogue", sans-serif;
  color: #311d1b;
  position: white;
}

.logo-container {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 10rem;
}

.logo-container img {
  max-width: 100%;
  max-height: 100%;
}

.not-found-header {
  font-size: 45px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #311d1b;
}

.not-found-description {
  font-size: 12px;
}

.not-found-header svg {
  margin-left: 10px;
}

.return-to-home {
  margin-top: 2rem;
  width: 100%;
  color: #311d1b;
}
</style>