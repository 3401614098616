<template>
  <div class="services">
    <nav class="service-nav">
      <router-link to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="black"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </router-link>
      <router-link to="/" class="logo">
        <img
          src="@/assets/logo-boards-transparent.png"
          alt="Boards N Thangs Logo"
        />
      </router-link>
    </nav>
    <section class="service catering" id="catering">
      <div id="cateringCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-if="loading" class="loading">
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
          </div>
          <div
            v-for="(image, index) in serviceInformation.catering.images"
            :key="index"
            :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
          >
            <img
              class="d-block w-100"
              :src="image"
              :alt="`Catering Image ${index + 1}`"
            />
          </div>
        </div>
      </div>
      <div class="service-icons">
        <div class="icon">
          <img src="@/assets/ingredient-1.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-2.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-3.png" alt="Ingredient 1" />
        </div>
      </div>
      <span class="service-name">{{ serviceInformation.catering.name }}</span>
      <span class="service-description">
        {{ serviceInformation.catering.description }}
      </span>
      <div class="service-background">
        <img src="@/assets/service-bg-1.png" alt="Service background" />
      </div>
    </section>
    <section class="service charcuterie" id="charcuterie">
      <div
        id="charcuterieCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div v-if="loading" class="loading">
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
          </div>
          <div
            v-for="(image, index) in serviceInformation.charcuterie.images"
            :key="index"
            :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
          >
            <img
              class="d-block w-100"
              :src="image"
              :alt="`Charcuterie Image ${index + 1}`"
            />
          </div>
        </div>
      </div>
      <div class="service-icons">
        <div class="icon">
          <img src="@/assets/ingredient-4.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-5.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-6.png" alt="Ingredient 1" />
        </div>
      </div>
      <span class="service-name">{{
        serviceInformation.charcuterie.name
      }}</span>
      <span class="service-description">
        {{ serviceInformation.charcuterie.description }}
      </span>
      <div class="service-background">
        <img src="@/assets/service-bg-2.png" alt="Service background" />
      </div>
    </section>
    <section class="service grazing" id="grazing">
      <div id="grazingCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-if="loading" class="loading">
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
            <div class="spinner-grow text-light" role="status"></div>
          </div>
          <div
            v-for="(image, index) in serviceInformation.grazing.images"
            :key="index"
            :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
          >
            <img
              class="d-block w-100"
              :src="image"
              :alt="`Grazing Image ${index + 1}`"
            />
          </div>
        </div>
      </div>
      <div class="service-icons">
        <div class="icon">
          <img src="@/assets/ingredient-7.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-8.png" alt="Ingredient 1" />
        </div>
        <div class="icon">
          <img src="@/assets/ingredient-9.png" alt="Ingredient 1" />
        </div>
      </div>
      <span class="service-name">{{ serviceInformation.grazing.name }}</span>
      <span class="service-description">
        {{ serviceInformation.grazing.description }}
      </span>
      <div class="service-background">
        <img src="@/assets/service-bg-3.png" alt="Service background" />
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  getGrazingImages,
  getCharcuterieImages,
  getCateringImages,
} from "@/composables/useFirebase";

export default {
  setup() {
    const route = useRoute();
    const service = ref(null);
    const serviceImages = ref([]);
    const loading = ref(true);
    const serviceInformation = ref({
      charcuterie: {
        name: "Charcuterie Couture",
        description:
          "Envision charcuterie boards that radiate an air of refined elegance, where an artful arrangement of thoughtfully chosen cheeses, delectable cured meats, and an assortment of meticulously paired accompaniments intertwine in a captivating display of flavors and artistry. Every board encapsulates the essence of culinary mastery, a harmonious symphony of textures and hues that invites you to partake in an unparalleled gourmet experience.",
        images: [],
      },
      grazing: {
        name: "Gourmet Grazing",
        description:
          "Envision a grand spread where artisanal cheeses, succulent charcuterie, and carefully curated accompaniments converge in a harmonious tapestry of flavors and aesthetics. Our grazing tables are culinary masterpieces, a sensory symphony that captivates both the palate and the eye. Whether it's an intimate gathering or a grand celebration, we tailor each table to infuse sophistication into your event. Elevate your occasion with our Grazing Table Services—a culinary indulgence like no other.",
        images: [],
      },
      catering: {
        name: "Exclusive Catering",
        description:
          "Discover the epitome of culinary refinement. Our exclusive catering services transform events into elegant culinary experiences, where every dish is a work of art, and each occasion radiates sophistication. Beyond exquisite food, we provide an unmatched level of service, attending to every detail to create an unforgettable experience for you and your guests. Elevate your event with our exclusive catering, leaving your guests craving for more.",
        images: [],
      },
    });
    onBeforeMount(async () => {
      if (
        route.query.s === undefined ||
        route.query.s === null ||
        !["catering", "grazing", "charcuterie"].includes(route.query.s)
      ) {
        window.location.href = "/services?s=catering";
      } else {
        service.value = route.query.s;

        const [cateringImages, charcuterieImages, grazingImages] =
          await Promise.all([
            getCateringImages(),
            getCharcuterieImages(),
            getGrazingImages(),
          ]);

        loading.value = false;

        serviceInformation.value.catering.images = cateringImages;
        serviceInformation.value.charcuterie.images = charcuterieImages;
        serviceInformation.value.grazing.images = grazingImages;
      }
    });

    onMounted(() => {
      if (
        route.query.s !== undefined &&
        route.query.s !== null &&
        ["catering", "grazing", "charcuterie"].includes(route.query.s)
      ) {
        const element = document.getElementById(route.query.s);

        element.scrollIntoView();
      }

      const cateringCarouselElement =
        document.getElementById("cateringCarousel");

      const charcuterieCarouselElement = document.getElementById(
        "charcuterieCarousel"
      );

      const grazingCarouselElement = document.getElementById("grazingCarousel");

      if (
        cateringCarouselElement &&
        charcuterieCarouselElement &&
        grazingCarouselElement
      ) {
        const cateringCarousel = new bootstrap.Carousel(
          cateringCarouselElement
        );

        const charcuterieCarousel = new bootstrap.Carousel(
          charcuterieCarouselElement
        );

        const grazingCarousel = new bootstrap.Carousel(grazingCarouselElement);

        cateringCarousel.next();
        charcuterieCarousel.next();
        grazingCarousel.next();
      }
    });

    return { service, serviceImages, serviceInformation, loading };
  },
};
</script>

<style scoped>
.services {
  min-height: 100vh;
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  padding-top: 7.5rem;
}
.service-nav {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  z-index: 5;
}

.service-nav .logo {
  max-height: 70%;
  max-width: 8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.service {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  overflow: hidden;
  scroll-margin-top: 7.5rem;
}

.carousel {
  width: 100%;
  height: 18rem;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.carousel-inner {
  background-color: rgb(213, 182, 144, 0.4);
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading div {
  margin: 0 10px;
}

.carousel-inner,
.carousel-item {
  height: 100%;
  width: 100%;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  box-shadow: 0 0 10px #855f35;
}

.service-name {
  font-family: "Pacifico", cursive;
  color: #855f35;
  font-size: 32px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.service-description {
  font-family: "Pacifico", cursive;
  color: #311d1b;
  text-align: left;
  font-size: 16px;
  padding: 15px 25px;
  position: relative;
  z-index: 2;
  margin-bottom: 1.5rem;
}

.service-icons {
  height: 7rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}

.service-icon-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  border-radius: 50%;
  background: #d5b690;
  width: 5rem;
  height: 5rem;
  margin: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.service-background {
  height: 50vh;
  position: absolute;
  bottom: 0;
  left: -2.4rem;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  opacity: 0.6;
}

#charcuterie .service-background {
  left: -3rem;
}
.service-background img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 500px) and (max-width: 649px) {
  .carousel {
    width: 35rem;
    height: 22rem;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 650px) {
  .service {
    width: 60rem;
    margin: 0 auto;
    overflow: unset;
  }

  .carousel {
    width: 40rem;
    height: 26rem;
    margin: 0 auto;
  }

  .service-description {
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .service-background,
  #charcuterie .service-background {
    left: 5rem;
  }
}
</style>