import {
  ref,
  getDownloadURL,
} from "firebase/storage";

import {
  getDoc,
  doc,
} from "firebase/firestore";

import { db, storage } from "../main";
export async function getCharcuterieImages() {
  let urls = [];

  try {
    const docRef = doc(db, "images", "charcuterie");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    const imagesUrls = data.urls;

    for (const image of imagesUrls) {
      const gsReferenceUrl = ref(storage, `charcuterie/${image}`);

      const url = await getDownloadURL(gsReferenceUrl);

      urls.push(url);
    }
  } catch (e) {
    console.log(e)
  }

  return urls;
}

export async function getGrazingImages() {
  let urls = [];

  try {
    const docRef = doc(db, "images", "grazing");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    const imagesUrls = data.urls;

    for (const image of imagesUrls) {
      const gsReferenceUrl = ref(storage, `grazing/${image}`);

      const url = await getDownloadURL(gsReferenceUrl);

      urls.push(url);
    }
  } catch (e) {
    console.log(e)
  }

  return urls;
}

export async function getCateringImages() {
  let urls = [];

  try {
    const docRef = doc(db, "images", "catering");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    const imagesUrls = data.urls;

    for (const image of imagesUrls) {
      const gsReferenceUrl = ref(storage, `catering/${image}`);

      const url = await getDownloadURL(gsReferenceUrl);

      urls.push(url);
    }
  } catch (e) {
    console.log(e)
  }

  return urls;
}